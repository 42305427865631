import Body from "../../../shared/common/Body";
import {useDispatch} from "react-redux";
import {Link, useLocation, useNavigate, useParams, useRoutes} from "react-router-dom";
import {Alert} from "react-bootstrap";
import ERoutes from "../../../core/enums/ERoutes";
import {useState} from "react";
import passwordRecoveryVerificationRequest, {
  IPasswordRecoveryVerificationRequest
} from "../../../core/api/requests/auth/passwordRecoveryVerificationRequest";
import EHttpStatusCodes from "../../../core/enums/EHttpStatusCodes";
import sweetalert2 from "sweetalert2";
import PasswordInput from "../../../shared/lib/PasswordInput";
import translator from "../../../core/utils/translator";
import "./PasswordRecovery.css";

export default function PasswordRecoveryVerify() {
  const dispatch = useDispatch();
  const navigator = useNavigate();
  const { search } = useLocation();

  const [ password, setPassword ] = useState<string>("");
  const [ passwordRepeat, setPasswordRepeat ] = useState<string>("");

  const [errors, setErrors] = useState({ password: "", repeatPassword: "" });

  const request = async (e: any) => {
    e.preventDefault();
    if (!password || password !== passwordRepeat) return;

    const data: IPasswordRecoveryVerificationRequest = {
      password,
      token: new URLSearchParams(search).get('t') || ''
    }

    const response = await passwordRecoveryVerificationRequest(
      dispatch,
      data,
    );

    if (response.statusCode === EHttpStatusCodes.ACCEPTED) {
      sweetalert2.fire(
        {
					title: `¡Excelente!`,
					html: `Su contraseña ha sido actualizada y <br /> es segura. Ya puede iniciar sesión de nuevo.`,
					icon: "success",
					confirmButtonText: "Volver al Inicio",
					customClass: {
						icon: 'mt-0 mb-14',
						confirmButton: 'px-5 mt-0 box-shadow-none',
						htmlContainer: 'mx-auto rounded-8 my-14',
						title: 'my-14 p-0',
						popup: 'py-52 px-57 rounded-8'
					}
				}
      ).then(() => navigator(ERoutes.AUTH_LOGIN))
    } else {
      sweetalert2.fire({
         title: 'Error',
         html: translator(response.data.message),
         icon: 'error',
         confirmButtonText: 'Entendido',
         customClass: {
          icon: 'mt-0 mb-14',
					confirmButton: 'px-5 mt-0 box-shadow-none',
					htmlContainer: 'mx-auto rounded-8 my-14',
					title: 'my-14 p-0',
					popup: 'py-52 px-57 rounded-8'
        }
       }
      )
    }
  }

  const setAndValidatePassword = (value: string) => {
    setPassword(value);

    let passwordError = "";
    let repeatPasswordError = errors.repeatPassword;

    if (value.length < 8) {
      passwordError = 'La clave debe contener mas de 8 caracteres';
    } else if (passwordRepeat !== "" && value !== passwordRepeat) {
      passwordError = 'Las contraseñas no coinciden.';
      repeatPasswordError = 'Las contraseñas no coinciden.';
    } else {
      repeatPasswordError = "";
    }

    setErrors({ password: passwordError, repeatPassword: repeatPasswordError });
  }

  const setAndValidateRepeatPassword = (value: string) => {
    setPasswordRepeat(value);

    let repeatPasswordError = "";
    let passwordError = errors.password;

    if (value !== password) {
      repeatPasswordError = 'Las contraseñas no coinciden.';
    } else {
      passwordError = "";
    }

    setErrors({ password: passwordError, repeatPassword: repeatPasswordError });
  }

  return (
    <section id="wrapper" className={"fullWidth"}>
      <div className="d-flex justify-content-between align-items-center full-height">
        <div className="form-container password-recovery" style={{
          width: '66%'
        }}>
          <div className="logo-container">
            <img src="/images/Onpole.png" alt="Logo" />
          </div>
          <h1 className="mb-5">Recuperar contraseña</h1>
          <p className="subtitle mb-5">
            Ya casi está. Introduce tu nueva contraseña y listo.
          </p>
          <form className="form-horizontal form-material" onSubmit={request}>
            <div className="">
              <div className="col-xs-12">
                <PasswordInput                
                 isInvalid={errors.password ? "input-error-focus mb-0" : ""}
                 placeholder="Contraseña"                 
                 value={password}
                 onChange={e => setAndValidatePassword(e.target?.value)}
                />
                {errors.password && <span className="error error-input mx-4">{errors.password}</span>}
              </div>
            </div>
            <div className="">
              <div className="col-xs-12">
                {/*<input className="form-control" type="password" required={true} placeholder="Repetir contraseña" value={passwordRepeat} onChange={(e) => setAndValidateRepeatPassword(e.target?.value)} />*/}
                <PasswordInput
                 isInvalid={errors.repeatPassword ? "input-error-focus mb-0" : ""}
                 placeholder="Repetir contraseña"                 
                 value={passwordRepeat}
                 onChange={e => setAndValidateRepeatPassword(e.target?.value)}
                />
                {errors.repeatPassword && <span className="error error-input mx-4">{errors.repeatPassword}</span>}
              </div>
            </div>

            <div className="form-group text-center">
              <div className="p-b-20">
                <button className={"w-auto h-auto px-5 py-3 disabled btn-reset"} type="submit">
                  Cambiar contraseña
                </button>
              </div>
            </div>

            <div className="form-group m-b-0 text-center">
              <Link to="/auth/login" className="link">
                <span className="mx-2">&larr;</span>Volver a Inicio de Sesión
              </Link>
            </div>
          </form>

        </div>
      <div
       className="image-container"
       style={{ backgroundImage: `url(/images/bannerRight.png)`, width: '33%' }}
      />
      </div>
    </section>
  )
}
