import IPermission from "../../core/interfaces/IPermission";
import EModules from "../../core/enums/EModules";
import ECommonPermissions from "../../core/enums/ECommonPermissions";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../core/store/store";
import {useNavigate} from "react-router-dom";
import {useEffect, useState} from "react";
import SecureView from "../../shared/common/SecureView";
import {Badge, Button} from "react-bootstrap";
import IReport from "../../core/interfaces/IReport";
import findReportsRequest from "../../core/api/requests/reports/findReportsRequest";
import moment from "moment";
import SeeReportModal from "../../shared/modals/SeeReportModal";
import translator from "../../core/utils/translator";

const permission: IPermission = {
  module: EModules.CIRCUITS,
  method: ECommonPermissions.FIND,
}

export default function ReportList () {
  const permissions = useSelector((state: RootState) => state.permissions)
  const dispatch = useDispatch();
  const navigator = useNavigate();

  const [ reports, setReports ] = useState<IReport[]>([]);

  const [ selectedReport, setSelectedReport ] = useState<IReport>()

  const find = async () => {

    const response = await findReportsRequest(
      dispatch,
    )

    if (response.data) {
      setReports(response.data)
    }
  }

  useEffect(() => {
    find()
  }, [])

  useEffect(() => {
    find()
  }, [selectedReport])

  return <SecureView permission={permission}>
    <SeeReportModal
      report={selectedReport}
      show={!!selectedReport}
      onClose={() => setSelectedReport(undefined)}
    />

<header className="row col-12 my-4 rounded-10 p-4 border border-2 border-black align-items-center">
				<div className={"col-12 gap-1 d-flex flex-column"}>
					<h2 className="text-24 font-medium">Denuncias Totales</h2>
					<span className="text-24 font-bold mt-2">{reports.length}</span>
				</div>
			</header>
      <div className="table-responsive row col-12 p-0 rounded-t-10">
            <table className="table table-bordered table-hover font-16 table-rounded">
              <thead>
              <tr>
                <th className="font-semibold">Fecha</th>
                <th className="font-semibold">Tipo</th>
                <th className="font-semibold">Estado</th>
                <th className="font-semibold">Creador</th>
                <th className="font-semibold">Acciones</th>
              </tr>
              </thead>
              <tbody>
              {reports.map((report) => {
                return <tr key={report.id}>
                  <td>{moment(report.createdAt).format('DD/MM/YYYY HH:mm:ss')}</td>
                  <td>
                    <Badge bg={'warning'}>{translator(report.type)}</Badge>
                  </td>
                  <td>
                    <Badge bg={'info'}>{translator(report.status)}</Badge>
                  </td>
                  <td>{report.owner?.userName} - {report.owner?.email}</td>
                  <td>
                    <Button size={'sm'} variant={'secondary'} onClick={() => setSelectedReport(report)}>
                      <i className={'ti-eye'}></i>
                    </Button>
                  </td>
                </tr>
              })
              }
              </tbody>
            </table>
          </div>    
  </SecureView>
}
