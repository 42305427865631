import CardBody from "../../shared/lib/CardBody";
import { Col, Row } from "react-bootstrap";
import translator from "../../core/utils/translator";
import React, { useEffect, useState } from "react";
import ICircuit from "../../core/interfaces/ICircuit";
import ModifyHoursModal from "../../shared/modals/ModifyHoursModal";
import updateCircuitRequest from "../../core/api/requests/circuits/updateCircuitRequest";
import EHttpStatusCodes from "../../core/enums/EHttpStatusCodes";
import sweetalert2 from "sweetalert2";
import {useDispatch} from "react-redux";
import Switch from '@mui/material/Switch';
import { TextField } from "@mui/material";
 
const DEBUG_MODE = window.location.hostname === 'localhost' && true;
export interface IInput {
  circuit: ICircuit;
  forceReload?(): void;
}

export default function (input: IInput) {
  const dispatch = useDispatch()
  const [showByDay, setShowByDay] = useState<any>({
    monday: false,
    tuesday: false,
    wednesday: false,
    thursday: false,
    friday: false,
    saturday: false,
    sunday: false,
  });
  const [operationHours, setOperationHours] = useState<any>(input.circuit?.operationHours || {});
  const [ day, setDay ] = useState<string>('');
  const [editHours, setEditHours] = useState<Record<string, number | null>>({});


  useEffect(() => {
    if (DEBUG_MODE) {
      console.log('Debug Mode Enabled');
      console.log('Circuit Update Data:', {
        operationHours: input.circuit?.operationHours,
        showByDay: showByDay
      });
      const tempData : any = {
        "monday": [
            {
                "start": "01:00",
                "end": "02:00",
                "month": []
            }
        ],
        "tuesday": [],
        "wednesday": [],
        "thursday": [
            {
                "start": "03:00",
                "end": "05:00",
                "month": []
            }
        ],
        "friday": [],
        "saturday": [],
        "sunday": [],
        "bankHoliday": []
    }
      Object.keys(tempData).map((oh : string) => {
        setShowByDay((prevShow : any) => ({
          ...prevShow,
          [oh]: tempData[oh] && tempData[oh].length > 0,
        }));
      });
      setOperationHours(tempData);
      return;
    }

    Object.keys(input.circuit?.operationHours || {}).map((oh) => {
      setShowByDay((prevShow: any) => ({
        ...prevShow,
        [oh]: input.circuit?.operationHours[oh] && input.circuit?.operationHours[oh].length > 0,
      }));
    });
  }, []);


  /*useEffect(() => {
    if (DEBUG_MODE) {
      console.log('Debug Mode Enabled');
      console.log('Day:', day);
      return;
    }

      setShowByDay((prevShow: any) => ({
        ...prevShow,
        [day]: !prevShow[day],
      }));

    // openModal();
  }, [day]);


  const openModal = () => {
    setShowByDay((prevShow: any) => ({
      ...prevShow,
      [day]: true,
    }));
    setShow(true);
  }

  const closeModal = () => {
    setShowByDay((prevShow: any) => ({
      ...prevShow,
      [day]: false,
    }));
    setShow(false);
  }*/

  const update = async (data: any) => {
    if (!input.circuit) return

    if (DEBUG_MODE) {

      sweetalert2.fire(
        `Debug Mode`,
        `Datos listos para enviar al backend`,
        "info"
      );

      console.log(' Debug Mode Enabled');
      console.log('Data to be sent to backend:', data);
      return;
    }

    const basicInformation: any = {
      action: 'basicInformation',
      data: {
        operationHours: data,
      }
    }

    const response = await updateCircuitRequest(dispatch, basicInformation, input.circuit.id)

    if (response.statusCode === EHttpStatusCodes.ACCEPTED) {
      sweetalert2.fire(
        `Excelente`,
        `Circuito ${input.circuit.name} modificado exitosamente.`,
        'success',
      )
    } else {
      sweetalert2.fire(
        `Error`,
        translator(response.data.message),
        'error',
      )
    }

    if (input.forceReload) {
      input.forceReload()
    }
  }

  const handleTimeChange = (day: string, index: number, field: string, value: string) => {
    const updatedHours = [...operationHours[day]];
    updatedHours[index][field] = value;
    setOperationHours((prev: any) => ({
      ...prev,
      [day]: updatedHours,
    }));
  };

  const addTimeSlot = (day: string) => {
    setOperationHours((prev: any) => ({
      ...prev,
      [day]: [...prev[day], { start: "00:00", end: "00:00", month: [] }],
    }));
  };

  const removeTimeSlot = (day: string, index: number) => {
    const updatedHours = operationHours[day].filter((_: any, i: number) => i !== index);
    setOperationHours((prev: any) => ({
      ...prev,
      [day]: updatedHours,
    }));
  };

  return (
    <CardBody>
      <Row>
        <Col>
          <div className={'time-title-container'}>
            <h4>Horarios</h4>
          </div>

          {Object.keys(operationHours || {}).map((oh) => (
            <div key={oh} className={'time-day-container center'}>
              <Switch
                className={'mr-3 font-poppins'}
                checked={operationHours[oh] && operationHours[oh].length > 0}
                sx={{
                  '& .MuiSwitch-switchBase.Mui-checked': {
                    color: '#a6051a',
                    opacity: 1
                  },
                  '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
                    backgroundColor: '#a6051a',
                    opacity: 1
                  },
                }}
                onChange={(e) => {
                  setShowByDay((prevShow: any) => ({
                    ...prevShow,
                    [oh]: e.target.checked,
                  }));
                  if (!e.target.checked) {
                    setOperationHours((prev: any) => ({
                      ...prev,
                      [oh]: [],
                    }));
                  } else {
                    addTimeSlot(oh);
                  }
                }}
                inputProps={{ 'aria-label': 'controlled' }}
              />
              <h5 className={'mr-3 font-poppins'} style={{ width: '90px'}}>{translator(oh)}</h5>
              <div className={'time-badge-container d-flex center'}>
                {operationHours[oh]?.map((h: any, index: number) => (
                  <div key={index} className={"d-flex center text-gray"}>
                    {editHours[oh] === index ? (
                      <TextField
                      className={"mr-2 font-poppins"}
                      id={`start-${index}`}
                      size="small"
                      variant="outlined"
                      value={h.start}
                      onChange={(e) => handleTimeChange(oh, index, 'start', e.target.value)}
                      disabled={editHours[oh] !== index}
                      sx={{
                        '& .MuiOutlinedInput-root': {
                          '& fieldset': {
                            border: '1px solid #e8e8e8', // Borde normal
                          },
                          '&:hover fieldset': {
                            border: '1px solid #e8e8e8', // Borde al pasar el mouse
                          },
                          '&.Mui-focused fieldset': {
                            border: '1px solid #e8e8e8 !important', // Borde al enfocar (con !important)
                          },
                        },
                        '& .MuiOutlinedInput-input': {
                          '&:focus': {
                            outline: 'none', // Elimina el outline del input
                          },
                        },
                      }}
                      />
                    ) : (
                      <span className={"mr-2 font-poppins"}>{h.start}</span>
                    )}
                    <span>-</span>
                    {editHours[oh] === index ? (
                    <TextField
                      className={"ml-2 font-poppins"}
                      id={`end-${index}`}
                      size="small"
                      variant="outlined"
                      value={h.end}
                      onChange={(e) => handleTimeChange(oh, index, 'end', e.target.value)}
                      disabled={editHours[oh] !== index}
                      sx={{
                        '& .MuiOutlinedInput-root': {
                          '& fieldset': {
                            border: '1px solid #e8e8e8', // Borde normal
                          },
                          '&:hover fieldset': {
                            border: '1px solid #e8e8e8', // Borde al pasar el mouse
                          },
                          '&.Mui-focused fieldset': {
                            border: '1px solid #e8e8e8 !important', // Borde al enfocar (con !important)
                          },
                        },
                        '& .MuiOutlinedInput-input': {
                          '&:focus': {
                            outline: 'none', // Elimina el outline del input
                          },
                        },
                      }}
                    />) : (
                      <span className={"ml-2 font-poppins"}>{h.end}</span>
                    )}
                    {editHours[oh] === index ? (
                      <i
                      className={'ti-check text-green p-2 pointer ml-2'}
                      onClick={() => {
                        update(operationHours)
                        setEditHours((prev) => ({ ...prev, [oh]: null }))
                      }}
                    ></i>
                    ) : (
                      <i
                      className={'ti-pencil text-green p-2 pointer ml-2'}
                      onClick={() => setEditHours((prev) => ({ ...prev, [oh]: index }))}
                    ></i>
                    )}
                    {operationHours[oh].length > 1 && (
                      <i
                        className={"ti-trash text-red p-2 pointer ml-2"}
                        onClick={() => removeTimeSlot(oh, index)}
                      ></i>
                    )}
                  </div>
                ))}
                {operationHours[oh] && operationHours[oh].length > 0 && (
                  <i
                    className={"ti-plus text-gray p-2 pointer ml-2"}
                    onClick={() => addTimeSlot(oh)}
                  ></i>
                )}
              </div>
            </div>
          ))}
        </Col>
      </Row>
    </CardBody>
  )
}
