import { useEffect, useRef, useState } from "react";
import { Badge } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import getMyCircuitsRequest from "../../core/api/requests/me/getMyCircuitsRequest";
import getMyProfileRequest from "../../core/api/requests/me/getMyProfileRequest";
import getFrontendUtilitiesRequest from "../../core/api/requests/utils/getFrontUtilitiesRequest";
import ERoutes from "../../core/enums/ERoutes";
import ICircuit from "../../core/interfaces/ICircuit";
import IMenuItem from "../../core/interfaces/IMenuItem";
import { endLoading, startLoading } from "../../core/store/slices/loadingSlice";
import {
	setCircuit,
	unselectCircuit,
} from "../../core/store/slices/selectedCompanySlice";
import { RootState } from "../../core/store/store";
import capitalizeFirstLetter from "../../core/utils/capitalizeFirstLetter";
import hasPermission from "../../core/utils/hasPermission";
import translator from "../../core/utils/translator";
import getVersion from "../../version";
import logo from "../styles/images/logo-transparent-red.png";
import menuList from "./menuList";

import offersSelected from "../styles/images/offers-selected.png";
import offers from "../styles/images/offers.png";

import circuitSelected from "../styles/images/circuits-selected.png";
import circuits from "../styles/images/circuits.png";

import racingSelected from "../styles/images/racing-selected.png";
import racing from "../styles/images/racing.png";

import eventsSelected from "../styles/images/events-selected.png";
import events from "../styles/images/events.png";

import usersSelected from "../styles/images/UsuariosSelected.svg";
import users from "../styles/images/Usuarios.svg";

import verificationsSelected from "../styles/images/VerificacionSelected.svg";
import verifications from "../styles/images/Verificacion.svg";

import denunciaSelected from "../styles/images/denunciasSelected.svg";
import denuncia from "../styles/images/denuncias.svg";

import tips from "../styles/images/consejos.svg";
import tipsSelected from "../styles/images/consejosSelected.svg";

import kart from "../styles/images/kart.svg";
import kartSelected from "../styles/images/kartSelected.svg";

import analiticas from "../styles/images/analiticas.svg";
import analiticasSelected from "../styles/images/analiticasSelected.svg";


export default function Sidebar() {
	const dispatch = useDispatch();
	const permissions = useSelector((state: RootState) => state.permissions);
	const availableCircuits = useSelector(
		(state: RootState) => state.availableCircuits
	);
	const selectedCompany = useSelector(
		(state: RootState) => state.selectedCompany
	);
	const userMe = useSelector((state: RootState) => state.userMe);
	const navigator = useNavigate();

	const [version, setVersion] = useState<string>("");
	const [menu, setMenu] = useState<IMenuItem[]>([]);
	
	const [searchTerm, setSearchTerm] = useState(""); // Texto ingresado por el usuario
	const [isDropdownOpen, setIsDropdownOpen] = useState(false); // Mostrar/ocultar opciones
	const dropdownRef = useRef<HTMLDivElement>(null);

	// Filtrar las opciones según el texto ingresado
	const filteredCircuits = availableCircuits.filter((circuit: ICircuit) =>
		circuit.name.toLowerCase().includes(searchTerm.toLowerCase())
	);

	// Manejar cambio en el input
	const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		e.preventDefault();
		setSearchTerm(e.target.value);
		setIsDropdownOpen(true); // Mostrar las opciones cuando el usuario escribe
	};

	// Seleccionar una opción
	const handleOptionClick = (circuit: ICircuit) => {
		setSearchTerm(circuit.name); // Actualizar el valor del input con el nombre del circuito
		setIsDropdownOpen(false); // Cerrar el desplegable

		if (!circuit.name) return;

		if (circuit.name === "-1") {
			dispatch(unselectCircuit());
			return;
		}

		// Actualizar el estado global con el circuito seleccionado
		dispatch(setCircuit(circuit));
	};

	/*const selectCircuit = (e: any) => {
		e.preventDefault();

		if (!e.target.value) return;
		
		if (e.target.value === "-1") {
			dispatch(unselectCircuit());
			return;
		}

		const circuit = availableCircuits.find(
			(ac: ICircuit) => ac.id === e.target.value
		);
		if (!circuit) return;

		dispatch(setCircuit(circuit));
	};*/
	
	const validateMenuByPermissions = () => {
		const menuTemp: IMenuItem[] = [];
		menuList.forEach((m) => {
			if (m.forCircuit && !selectedCompany?.circuit) return;

			if (!m.permission) {
				menuTemp.push(m);
			} else if (hasPermission(permissions, m.permission)) {
				menuTemp.push(m);
			}
		});

		setMenu(menuTemp);
	};

	const getSelectedCompany = () => {
		const sc = sessionStorage.getItem("sc");
		if (sc) {
			dispatch(setCircuit(JSON.parse(sc)));
		}
	};

	const getMyProfile = async () => await getMyProfileRequest(dispatch);

	const getMyCompanyProfile = async () => {};

	const getMyCompanies = async () => await getMyCircuitsRequest(dispatch);

	const getFrontUtilities = async () =>
		await getFrontendUtilitiesRequest(dispatch);

	const initialRequests = async () => {
		dispatch(startLoading());
		getSelectedCompany();
		await Promise.all([getMyProfile(), getMyCompanies(), getFrontUtilities()]);
		dispatch(endLoading());
	};

	useEffect(() => {
		initialRequests();
	}, []);

	useEffect(() => validateMenuByPermissions(), [permissions]);

	useEffect(() => {
		(async () => {
			dispatch(startLoading());
			await getMyCompanyProfile();
			validateMenuByPermissions();
			dispatch(endLoading());
		})();
	}, [selectedCompany.circuit]);

	useEffect(() => {
		if (userMe.user?.firstInit) navigator(ERoutes.FORCE_PASSWORD_CHANGE);
	}, [userMe.user]);

	useEffect(() => {
		const handleClickOutside = (event: MouseEvent) => {
		  if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
			setIsDropdownOpen(false);
		  }
		};
	  
		document.addEventListener("mousedown", handleClickOutside);
		return () => document.removeEventListener("mousedown", handleClickOutside);
	  }, []);

	return (
		<>
			<aside className="left-sidebar pt-2 mx-auto" id={"sidebar"}>
				<div className="aside-logo">
					<div className={"company-information my-3"}>
						<a href={'/users'}>
							<img src={logo} alt="Logo OnPole" />
						</a>
					</div>
					{userMe?.user && (
						<div className={"user-profile mt-4"}>
							<span className="hide-menu font-16 font-poppins leading-24" style={{ marginBottom: '4px'}}>
								{userMe?.user.name} {userMe.user.lastName}
							</span>
							<small className="font-poppins text-14 font-regular leading-24" style={{ marginBottom: '4px'}}>{userMe.user.email}</small>
							<small className="font-poppins text-14 font-regular leading-24">{capitalizeFirstLetter(translator(userMe.user.role.name))}</small>
						</div>
					)}

					<hr />
					{availableCircuits?.length ? (
						<div ref={dropdownRef} className={"company-selector position-relative"}>
							<div className="select-icon-container">
								<img src={circuits} alt={"circuit"} className="select-icon" />
								<input
								type="text"
								className="custom-select"
								placeholder="Seleccionar Circuito"
								value={searchTerm}
								onChange={handleInputChange}
								onFocus={() => setIsDropdownOpen(true)} // Abrir el menú al enfocar
								/>								
							</div>

							{/* Lista de opciones desplegables */}
							{isDropdownOpen && filteredCircuits?.length > 0 && (
								<ul className="dropdown-menu show position-absolute w-100">
								{filteredCircuits.map((circuit: ICircuit) => (
									<li
									key={circuit.id}
									className="dropdown-item text-wrap"
									onClick={() => handleOptionClick(circuit)}
									>
									{circuit.name}
									</li>
								))}
								</ul>
							)}
								{isDropdownOpen && filteredCircuits?.length === 0 && (
									<div className="alert alert-warning mt-2">
									No se encontraron circuitos.
									</div>
								)}																		
						</div>
					) : (
						<div className={"alert alert-warning"}>No tienes circuitos asignados</div>
					)}

					<hr className="mb-0" />
				</div>
				<nav className="sidebar-nav">
					<ul className="menu-buttons">
						{menu.map((m) => {							
							const selected = m.redirectTo === document.location.pathname;
							if (m.name === "Separador") return <hr className="my-0" />;
							// if (m.name === "Analíticas" && userMe.user?.role.name === "administrator") return null;
							if (m.name === "Planes" && userMe.user?.role.name === "administrator") return null;
							return (
								<li
									key={`${m.name}${Date.now()}`}
									className={`${selected ? "selected" : ""}`}
								>
									<Link className="d-flex align-items-center pr-0 text-16" to={m.redirectTo}>
										{m.icon.includes("rc-") ? (
											<>
												{selected ? (
													<>
														{m.icon === "rc-analiticas" && (
															<img
																className={"icon-onpole"}
																width={15}
																height={"auto"}
																src={analiticasSelected}
																alt={"analiticas"}
															/>
														)}
														{m.icon === "rc-kart" && (
															<img
																className={"icon-onpole"}
																width={15}
																height={"auto"}
																src={kartSelected}
																alt={"kart"}
															/>
														)}
														{m.icon === "rc-tips" && (
															<img
																className={"icon-onpole"}
																width={15}
																height={"auto"}
																src={tipsSelected}
																alt={"tips"}
															/>
														)}
														{m.icon === "rc-denuncia" && (
															<img
																className={"icon-onpole"}
																width={15}
																height={"auto"}
																src={denunciaSelected}
																alt={"denuncia"}
															/>
														)}
														{m.icon === "rc-verifications" && (
															<img
																className={"icon-onpole"}
																width={15}
																height={"auto"}
																src={verificationsSelected}
																alt={"verifications"}
															/>
														)}
														{m.icon === "rc-users" && (
															<img
																className={"icon-onpole"}
																width={15}
																height={"auto"}
																src={usersSelected}
																alt={"users"}
															/>
														)}
														{m.icon === "rc-offers" && (
															<img
																className={"icon-onpole"}
																width={15}
																height={"auto"}
																src={offersSelected}
																alt={"offers"}
															/>
														)}

														{m.icon === "rc-circuit" && (
															<img
																className={"icon-onpole"}
																width={15}
																height={"auto"}
																src={circuitSelected}
																alt={"circuit"}
															/>
														)}

														{m.icon === "rc-events" && (
															<img
																className={"icon-onpole"}
																width={15}
																height={"auto"}
																src={eventsSelected}
																alt={"events"}
															/>
														)}

														{m.icon === "rc-racing" && (
															<img
																className={"icon-onpole"}
																width={15}
																height={"auto"}
																src={racingSelected}
																alt={"racing"}
															/>
														)}
													</>
												) : (
													<>
														{m.icon === "rc-analiticas" && (
															<img
																className={"icon-onpole"}
																width={15}
																height={"16"}
																src={analiticas}
																alt={"analiticas"}
															/>
														)}
														{m.icon === "rc-kart" && (
															<img
																className={"icon-onpole"}
																width={15}
																height={"auto"}
																src={kart}
																alt={"kart"}
															/>
														)}
														{m.icon === "rc-tips" && (
															<img
																className={"icon-onpole"}
																width={15}
																height={"auto"}
																src={tips}
																alt={"tips"}
															/>
														)}
														{m.icon === "rc-denuncia" && (
															<img
																className={"icon-onpole"}
																width={15}
																height={"auto"}
																src={denuncia}
																alt={"denuncia"}
															/>
														)}
														{m.icon === "rc-verifications" && (
															<img
																className={"icon-onpole"}
																width={15}
																height={"auto"}
																src={verifications}
																alt={"verifications"}
															/>
														)}
														{m.icon === "rc-users" && (
															<img
																className={"icon-onpole"}
																width={15}
																height={"auto"}
																src={users}
																alt={"users"}
															/>
														)}
														{m.icon === "rc-offers" && (
															<img
																className={"icon-onpole"}
																width={15}
																height={"auto"}
																src={offers}
																alt={"offers"}
															/>
														)}

														{m.icon === "rc-circuit" && (
															<img
																className={"icon-onpole"}
																width={15}
																height={"auto"}
																src={circuits}
																alt={"circuit"}
															/>
														)}

														{m.icon === "rc-events" && (
															<img
																className={"icon-onpole"}
																width={15}
																height={"auto"}
																src={events}
																alt={"events"}
															/>
														)}

														{m.icon === "rc-racing" && (
															<img
																className={"icon-onpole"}
																width={15}
																height={15}
																src={racing}
																alt={"racing"}
															/>
														)}
													</>
												)}
											</>
										) : (
											<i className={m.icon} style={{ fontSize: '15px'}}></i>
										)}
										{m.name}
									</Link>
								</li>
							);
						})}
						{userMe.user?.role.name === 'client' && <li className={`${document.location.pathname === '/plans' ? "selected" : ""}`}>
							<Link className="d-flex align-items-center pr-0 text-16" to={'/plans'}>
							<i className='ti-list' style={{ fontSize: '15px'}}></i>
								Planes
							</Link>
						</li>}
					</ul>

					<div className={"buttons-footer"} style={{ backgroundColor: 'white', paddingBottom: '32px', position: 'fixed', bottom: '0', width: '240px' }}>
						<ul>
							<li>
								<Link className="text-16 d-flex align-items-center" to={ERoutes.AUTH_LOGOUT}>
									<i className="icon-logout" style={{ marginRight: '10px', fontSize: '14px', paddingLeft: '1px' }}></i>
									Cerrar Sesión
								</Link>
							</li>
						</ul>
						<hr className="pt-2 pb-0 m-0" />
					</div>
				</nav>
			</aside>
		</>
	);
}
