import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import ERoutes from "../../../core/enums/ERoutes";
import sweetalert2 from "sweetalert2";
import passwordRecoveryRequest from "../../../core/api/requests/auth/passwordRecoveryRequest";
import { useDispatch } from "react-redux";
import "./PasswordRecovery.css";


export default function PasswordRecovery() {
	const [email, setEmail] = useState("");
	const [errors, setErrors] = useState({ email: "" });

	const validateEmail = (email: string) => {
		const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
		return emailRegex.test(email);
	};

	const dispatch = useDispatch();
	const navigator = useNavigate();

	const request = async (e: any) => {
		e.preventDefault();
		let valid = true;
		let emailError = "";

		if (!validateEmail(email)) {
			emailError = "Correo incorrecto.";
			valid = false;
		}

		setErrors({ email: emailError });

		if (valid) {
			await passwordRecoveryRequest(dispatch, {
				email,
			});

			sweetalert2
				.fire({
					title: `¡Correo en camino!`,
					html: `Si no aparece pronto, comprueba tu carpeta de spam. Lo enviamos desde la dirección de correo electrónico: <b>no-reply@onpole.io</b>`,
					icon: "success",
					confirmButtonText: "Perfecto",
					customClass: {
						icon: 'mt-0 mb-14',
						confirmButton: 'px-5 mt-0 box-shadow-none',
						htmlContainer: 'mx-auto rounded-8 my-14',
						title: 'my-14 p-0',
						popup: 'py-52 px-57 rounded-8'
					}
				})
				.then(() => {
					navigator(ERoutes.AUTH_LOGIN);
				});
		}
	};

	return (
		<div className="d-flex justify-content-between align-items-center full-height">
			<div className="form-container password-recovery w-66">
				<div className="logo-container">
					<img src="/images/Onpole.png" alt="Logo" />
				</div>
				<h1 className="mb-5">Recuperar contraseña</h1>
				<p className="subtitle mb-5">
					Todo bien. Introduce la dirección de correo electrónico de tu cuenta y te <br></br>
					enviaremos un enlace para restablecer tu contraseña.
				</p>
				<form onSubmit={request}>
					<input
						className={errors.email ? "input-error-focus" : ""}
						type="email"
						placeholder="Correo Electrónico"
						value={email}
						onChange={(e) => setEmail(e.target.value)}
					/>
					{errors.email && <span className="error error-input mx-4">{errors.email}</span>}
					<button className="btn-login" type="submit">
						Enviar Solicitud
					</button>
					<Link to="/auth/login" className="link">
						<span className="mx-2">&larr;</span>Volver a Inicio de Sesión
					</Link>
				</form>
			</div>
			<div
				className="image-container w-33"
				style={{ backgroundImage: `url(/images/bannerRight.png)` }}
			/>
		</div>
	);
}
