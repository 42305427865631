import { Button, Form, InputGroup, ListGroup } from "react-bootstrap";
import IUser from "../../../core/interfaces/IUser";
import { useEffect, useState } from "react";
import findUsersRequest from "../../../core/api/requests/users/findUsersRequest";
import { useDispatch } from "react-redux";

export default function UserSearchDropdown({ onSelectUser }: { onSelectUser: (email: string) => void }) {
    const dispatch = useDispatch();
    const [query, setQuery] = useState<string>("");
  const [users, setUsers] = useState<IUser[]>([]);
  const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false);

  const findUsers = async () => {
    if (!query) {
      setUsers([]);
      return;
    }

    const response = await findUsersRequest(dispatch, {
      page: 1,
      perPage: 10,
      query,
    });

    if (response?.data) {
      setUsers(response.data.data || []);
      setIsDropdownOpen(true); // Abrir el dropdown al obtener resultados
    }
  };

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      findUsers();
    }, 300); // Debounce para evitar múltiples llamadas mientras el usuario escribe

    return () => clearTimeout(delayDebounceFn);
  }, [query]);

  const handleSelectUser = (user: IUser) => {    
    setQuery(""); // Limpiar el input
    setUsers([]); // Limpiar la lista de usuarios
    setIsDropdownOpen(false); // Cerrar el dropdown
    
    onSelectUser(user.email); // Llamar a la función para agregar el usuario
  };

  return (
    <div className="position-relative mb-5">
      <InputGroup>
        <Form.Control
          className="rounded-10"
          placeholder="Buscar por nombre de usuario"
          value={query}
          onChange={(e) => setQuery(e.target.value)}
          onFocus={() => setIsDropdownOpen(true)} // Abrir el dropdown al enfocar el input
        />
        <Button
          id="button-addon2"
          className="relative bg-transparent"
          style={{ boxShadow: "none" }}
          onClick={findUsers}
        >
          <i className="ti-search"></i>
        </Button>
      </InputGroup>

      {isDropdownOpen && users.length > 0 && (
        <ListGroup className="position-absolute w-100 mt-1" style={{ zIndex: 1000, overflowY: "auto", maxHeight: "100px" }}>
          {users.map((user) => (
            <ListGroup.Item
              key={user.email}
              action
              onClick={() => handleSelectUser(user)}
              className="cursor-pointer font-poppins font-16 py-1"
            >
              {user.name} - {user.email} - {user.userName}
            </ListGroup.Item>
          ))}
        </ListGroup>
      )}

      {isDropdownOpen && users.length === 0 && query && (
        <ListGroup className="position-absolute w-100 mt-1" style={{ zIndex: 1000 }}>
          <ListGroup.Item className="font-poppins font-16">No se encontraron usuarios.</ListGroup.Item>
        </ListGroup>
      )}
    </div>
  );
}