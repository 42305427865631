export default {
  "user not found": "Usuario inexistente",
  "invalid password": "Contraseña incorrecta. Comprueba la contraseña e inténtalo de nuevo.",
  beginner: "Principiante",
  active: "Activa",
  finished: "Finalizada",
  private: "Privada",
  public: "Pública",
  toStart: "Por empezar",
  running: "En curso",
  closed: "Cerrada",
  sodiKart390cc: "Sodi Kart 390cc",
  "invalid join code":
    "El código de la liga es incorrecto. Asegúrate de usar el código correcto.",
  byTime: "Por tiempo",
  byPosition: "Por posición",
  administrator: "Administrador",
  verified: "Verificado",
  verifying: "Verificando",
  pending: "Pendiente",
  male: "Masculino",
  famale: "Femenino",
  es: "Español",
  lessYear: "Menos de 1 año",
  lessThreeYears: "Menos de 3 años",
  lessFiveYears: "Menos de 5 años",
  moreFiveYears: "Más de 5 años",
  excellent: "Excelente",
  good: "Bueno",
  normal: "Normal",
  bad: "Malo",
  veryBad: "Muy Malo",
  intermediate: "Intermedio",
  advance: "Avanzado",
  expert: "Experto",
  professional: "Profesional",
  withOutLevel: "Sin Nivel",
  freeParking: "Parking gratuito",
  "free parking": "Parking gratuito",
  coveredParking: "Parking cubierto",
  payedParking: "Parking",
  coffeeShop: "Cafetería",
  "coffee shop": "Cafetería",
  restaurant: "Restaurante",
  bar: "Bar",
  terrace: "Terraza",
  market: "Tienda",
  wifi: "WiFi",
  disabledAccess: "Acceso especial",
  dressingRooms: "Vestuarios",
  transport: "Transporte",
  "disabled access": "Acceso especial",
  "zona ocio": "Zona de ocio",
  paintball: "Paintball",
  "dressing rooms": "Vestidores",
  "data/userName must NOT have more than 12 characters":
    "El nombre de usuario no puede tener más de 12 caracteres.",
  'data/password must match pattern "^[A-Za-z0-9!.@]{8,100}$"':
    "La contraseña debe tener 8 caracteres, incluyendo al menos una letra y un número.",
  outdoor: "Outdoor",
  indoor: "Indoor",
  "data/name must NOT have fewer than 1 characters":
    "El nombre debe tener más de 1 caracter",
  "data/title must NOT have fewer than 1 characters":
    "El nombre debe tener más de 1 caracter",
  "data/level must be equal to one of the allowed values":
    "Debes definir el nivel",
  profile: "Perfil",
  post: "Post",
  monday: "Lunes",
  tuesday: "Martes",
  wednesday: "Miercoles",
  thursday: "Jueves",
  friday: "Viernes",
  saturday: "Sabados",
  sunday: "Domingos",
  bankHoliday: "Feriados",
  notVerified: "No verificado",
  "jwt expired": "El token expiró. Por favor, solicita un nuevo reestablecimiento de contraseña.",
  "data/token must NOT have fewer than 1 characters": "El token no fue proporcionado",
  'data/email must match format "email"': "El correo electrónico no es válido",
  "data/services/0 must be equal to one of the allowed values": "Debes seleccionar un servicio valido", 
  "data/length must be a number": "La longitud debe ser un número",
  "data/length must be number": "La longitud debe ser un número",
  "data/services/1 must be equal to one of the allowed values": "Debes seleccionar un servicio valido",
  "data/services/2 must be equal to one of the allowed values": "Debes seleccionar un servicio valido",
  "data/services/3 must be equal to one of the allowed values": "Debes seleccionar un servicio valido",
  "data/services/4 must be equal to one of the allowed values": "Debes seleccionar un servicio valido",
  "data/services/5 must be equal to one of the allowed values": "Debes seleccionar un servicio valido",
  "data/services/6 must be equal to one of the allowed values": "Debes seleccionar un servicio valido",
  "data/services/7 must be equal to one of the allowed values": "Debes seleccionar un servicio valido",
  "data/services/8 must be equal to one of the allowed values": "Debes seleccionar un servicio valido",
  "data/services/9 must be equal to one of the allowed values": "Debes seleccionar un servicio valido",
  "data/services/10 must be equal to one of the allowed values": "Debes seleccionar un servicio valido",
  "data/services/11 must be equal to one of the allowed values": "Debes seleccionar un servicio valido",
  "data/services/12 must be equal to one of the allowed values": "Debes seleccionar un servicio valido",
};
