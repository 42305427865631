import { FormControlLabel, FormGroup, Switch, Tabs, Tab, Box, FormControl, MenuItem, Select, SelectChangeEvent, InputLabel, TextField, Input, InputAdornment, IconButton, Typography } from "@mui/material";
import moment from "moment/moment";
import { ChangeEvent, useEffect, useState, forwardRef } from "react";
import { Button, Col, Form, InputGroup, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import sweetalert2 from "sweetalert2";
import addCircuitUser from "../../core/api/requests/circuits/addCircuitUser";
import findByIdCircuitRequest from "../../core/api/requests/circuits/findByIdCircuitRequest";
import removeCircuitUser from "../../core/api/requests/circuits/removeCircuitUser";
import updateCircuitRequest from "../../core/api/requests/circuits/updateCircuitRequest";
import ECommonPermissions from "../../core/enums/ECommonPermissions";
import EHttpStatusCodes from "../../core/enums/EHttpStatusCodes";
import EModules from "../../core/enums/EModules";
import ERoutes from "../../core/enums/ERoutes";
import IAddress from "../../core/interfaces/IAddress";
import ICircuit from "../../core/interfaces/ICircuit";
import IPermission from "../../core/interfaces/IPermission";
import { RootState } from "../../core/store/store";
import constants from "../../core/utils/constants";
import defaults from "../../core/utils/defaults";
import translator from "../../core/utils/translator";
import SecureView from "../../shared/common/SecureView";
import Card from "../../shared/lib/Card";
import CardBody from "../../shared/lib/CardBody";
import ProfileImage from "../../shared/lib/ProfileImage";
import ChangeAddressModal from "../../shared/modals/ChangeAddressModal";
import CircuitHours from "./CircuitHours";
import * as React from 'react';
import  Grid  from '@mui/material/Grid';
import kartSelected from "../../shared/styles/images/kart.svg";
import IUser from "../../core/interfaces/IUser";
import findUsersRequest from "../../core/api/requests/users/findUsersRequest";
import UserSearchDropdown from "./circuitModify/UserSearchDropdown";
import steeringWheel from "../../shared/icons/steering-wheel.svg"


const permission: IPermission = {
	module: EModules.CIRCUITS,
	method: ECommonPermissions.FIND_BY_ID,
};

interface TabPanelProps {
	children?: React.ReactNode;
	index: number;
	value: number;
}

const tabs = [
	{ id: "informacion-general", label: "Información General" },
	{ id: "horarios", label: "Horarios" },
	{ id: "tipos-de-kart", label: "Tipos de Kart" },
	{ id: "servicios", label: "Servicios" },
];

const DEBUG_MODE = window.location.hostname === 'localhost' && true;

export default function CircuitModify () {
	const permissions = useSelector((state: RootState) => state.permissions);
	const dispatch = useDispatch();
	const navigator = useNavigate();
	const params = useParams();

	const [circuit, setCircuit] = useState<ICircuit>();
	//const [length, setLength] = useState<number|undefined>(circuit?.length);

	const [newUser, setNewUser] = useState<string>("");

	const [modalLocation, setModalLocation] = useState<boolean>(false);
	//const [modalName, setModalName] = useState<boolean>(false);

	//const [error, setError] = useState<string>("");
	const [kart, setKart] = useState<string>("");
	const [time, setTime] = useState<number | undefined>(undefined);

	const [forceReload, setForceReload] = useState<boolean>(false);

	const [tabValue, setTabValue] = useState(0);
	// Find, add and remove users from circuit
	const [users, setUsers] = useState<IUser[]>([])
	const [page, setPage] = useState<number>(1);
	const [perPage, setPerPage] = useState<number>(30);
	const [query, setQuery] = useState<string>("");
	const [addUserShow, setAddUserShow] = useState<boolean>(false);
	const [editNameCircuit, setEditNameCircuit] = useState<boolean>(false);

	const [activeTab, setActiveTab] = useState<string>("informacion-general");

	const handleTabChange = (tabId: string) => {
		setActiveTab(tabId);
	};

	const serviceIcons: any = {
		"free parking": "ti-direction",
		transport: "ti-car",
		restaurant: "ti-home",
		"coffee shop": "ti-home",
		bar: "ti-filter",
		terrace: "ti-comments",
		market: "ti-shopping-cart",
		wifi: "ti-world",
		"disabled access": "ti-wheelchair",
		"dressing rooms": "ti-user",
		"zona ocio": "ti-game",
		paintball: "ti-paint-roller",
		Simracing: steeringWheel,
	};

	// const changeLocation = async (address: IAddress) => {
	// 	if (!circuit) return;
	//
	// 	setCircuit((prevState: ICircuit | undefined) => {
	// 		if (!prevState) return prevState;
	//
	// 		return {
	// 			...prevState,
	// 			address,
	// 		};
	// 	});
	// };

	const handleChange = (event: React.SyntheticEvent, newValue: number) => {
		setTabValue(newValue);
	};

 /*function CustomTabPanel(props: TabPanelProps) {
		const { children, value, index, ...other } = props;

		return (
			<div
				role="tabpanel"
				hidden={value !== index}
				id={`simple-tabpanel-${index}`}
				aria-labelledby={`simple-tab-${index}`}
				{...other}
			>
				{value === index && <Box sx={{ p: 3 }}>{children}</Box>}
			</div>
		);
	}

	function a11yProps(index: number) {
		return {
			id: `simple-tab-${index}`,
			'aria-controls': `simple-tabpanel-${index}`,
		};
	}*/	

	useEffect(() => {
		if (params?.id) {
			get(params.id);
		} else goBack();
	}, []);

	useEffect(() => {
		if (params?.id) {
			get(params.id);
			setForceReload(false);
		} else goBack();
	}, [forceReload]);

	const goBack = () => navigator(ERoutes.CIRCUITS_LIST);

	const get = async (id: string) => {
		const response = await findByIdCircuitRequest(dispatch, id);

		if (response.data) {
			setCircuit(response.data);
		} else goBack();
	};

	const setValue = (field: string, value: any) => {
		const c = circuit;
		if (!c) return;

		switch (field) {
			case "service":
				c.services = c?.services || [];

				if (c.services.includes(value)) {
					c.services = c.services.filter((s) => s !== value);
				} else {
					c.services.push(value);
				}

				break;
			case "kart":
				c.kartingTypes = c?.kartingTypes || [];
				c.kartingTimes = c?.kartingTimes || [];

				if (c.kartingTypes.includes(value)) {
					c.kartingTypes = c.kartingTypes.filter((s) => s !== value);
					c.kartingTimes = c.kartingTimes.filter((s) => s.kart !== value);
				} else {
					if (!time || time <= 1) {
						sweetalert2.fire(
							`Error`,
							"Es obligatorio introducir un tiempo.",
							"error"
						);
						return;
					}
					c.kartingTypes.push(value);
					c.kartingTimes.push({ kart: value, time });

					setKart("");
					setTime(0);
				}

				break;
			case "open":
				changeOpen(!c.open);
				break;
			case "premium":
				changePremium(!c.premium);
				break;
			case "name":
				c.name = value;
				break;
			case "type":
				c.type = value;
				break;
			case "website":
				c.website = value;
				break;
			case "phone":
				c.phoneNumbers = [value];
				break;
			case "length":
				c.length = parseInt(value);
				break;
			case "newUser":
				setNewUser(value);
				return;
			default:
				return;
		}

		if (c) {
			setCircuit({
				...c,
			});
		}
	};

	const removeUser = async (email: string) => {
		if (!email || !circuit?.id) return;

		const response = await removeCircuitUser(dispatch, email, circuit.id);

		setNewUser("");

		if (response.statusCode === EHttpStatusCodes.ACCEPTED) {
			sweetalert2
				.fire({
					title: 'Excelente',
					html: `Usuario ${email} removido exitosamente.`,
					icon: 'success',
					confirmButtonText: 'Ok',
					customClass: {
						icon: 'mt-0 mb-14',
						confirmButton: 'px-5 mt-0 box-shadow-none',
						htmlContainer: 'mx-auto rounded-8 my-14',
						title: 'my-14 p-0',
						popup: 'py-52 px-57 rounded-8'
					}
		})
				.then(() => {
					get(circuit.id);
				});
		} else {
			sweetalert2.fire({
				title: 'Error',
				html: translator(response.data.message),
				icon: 'error',
				confirmButtonText: 'Ok',
				customClass: {
					icon: 'mt-0 mb-14',
					confirmButton: 'px-5 mt-0 box-shadow-none',
					htmlContainer: 'mx-auto rounded-8 my-14',
					title: 'my-14 p-0',
					popup: 'py-52 px-57 rounded-8'
				}
	});
		}
	};

	const addUser = async (email: string) => {
		if (!email || !circuit?.id) return;		
		
		const response = await addCircuitUser(dispatch, email, circuit.id);
		

		setNewUser("");

		if (response.statusCode === EHttpStatusCodes.ACCEPTED) {
			sweetalert2
				.fire({
											title: 'Excelente',
											html: `Usuario ${email} agregado exitosamente.`,
											icon: 'success',
											confirmButtonText: 'Ok',
											customClass: {
												icon: 'mt-0 mb-14',
												confirmButton: 'px-5 mt-0 box-shadow-none',
												htmlContainer: 'mx-auto rounded-8 my-14',
												title: 'my-14 p-0',
												popup: 'py-52 px-57 rounded-8'
											}
								}
					)
				.then(() => {
					get(circuit.id);
				});
		} else {
			sweetalert2.fire({
				title: 'Error',
				html: response.data.message.includes("was registered in this circuit before") ? "El usuario ya está agregado." : translator(response.data.message),
				icon: 'error',
				confirmButtonText: 'Ok',
				customClass: {
					icon: 'mt-0 mb-14',
					confirmButton: 'px-5 mt-0 box-shadow-none',
					htmlContainer: 'mx-auto rounded-8 my-14',
					title: 'my-14 p-0',
					popup: 'py-52 px-57 rounded-8'
				}
	});
		}
	};

	const find = async (pageByParam?: number) => {
		if (pageByParam) setPage(pageByParam);
	
		const response = await findUsersRequest(dispatch, {
			page: pageByParam || page,
			perPage,
			query,
		});		
	
		if (response?.data) {
			setUsers(response.data?.data || []);	
			setPage(response.data?.pagination?.page || 1);
			setPerPage(response.data?.pagination?.perPage || 1);
		}
	};
	
	useEffect(() => {
		find();
	}, []);
	
	useEffect(() => {
		setPage(1);
	}, [query]);
	
	const changeOpen = async (value: boolean) => {
		if (!circuit) return;
		const restricted: any = {
			action: "basicInformation",
			data: {
				open: value,
			},
		};

		const response = await updateCircuitRequest(dispatch, restricted, circuit.id);

		if (response.data) {
			setCircuit(response.data);
		}
	};

	const changePremium = async (value: boolean) => {
		if (!circuit) return;
		const restricted: any = {
			action: "restrictedInformation",
			data: {
				premium: value,
			},
		};

		const response = await updateCircuitRequest(dispatch, restricted, circuit.id);

		if (response.data) {
			setCircuit(response.data);
		}
	};

	const update = async () => {
		if (!circuit) return;

		const basicInformation: any = {
			action: "basicInformation",
			data: {
				website: circuit.website,
				length:
					typeof circuit.length === "string"
						? parseInt(circuit.length)
						: circuit.length,
				services: circuit.services,
				kartingTypes: circuit.kartingTypes,
				kartingTimes: circuit.kartingTimes,
				address: circuit.address,
			},
		};

		const restricted: any = {
			action: "restrictedInformation",
			data: {
				name: circuit.name,
				type: circuit.type,
			},
		};

		/*if (DEBUG_MODE) {
			console.log('�� Debug Mode Enabled');
			console.log('Circuit Update Data:', {
				basicInformation,
				restricted,
				phoneNumber: circuit.phoneNumbers?.[0],
				circuitId: circuit.id
			});
			
			// Simular respuesta exitosa
			sweetalert2.fire(
				`Excelente`,
				`Datos actualizados exitosamente.`,
				"info"
			);
			return;
		}*/

		if (circuit.phoneNumbers && circuit.phoneNumbers.length) {
			const phoneNumber: any = {
				action: "phoneNumber",
				data: {
					number: circuit.phoneNumbers[0],
				},
			};
			await updateCircuitRequest(dispatch, phoneNumber, circuit.id);
		}

		const response = await Promise.all([
			updateCircuitRequest(dispatch, basicInformation, circuit.id),
			updateCircuitRequest(dispatch, restricted, circuit.id),
		]);

		if (
			response.length > 0 &&
			response[0].statusCode === EHttpStatusCodes.ACCEPTED
		) {
			sweetalert2
				.fire({
					title: 'Excelente',
					html: `Circuito ${circuit.name} modificado exitosamente.`,
					icon: 'success',
					confirmButtonText: 'Ok',
					customClass: {
						icon: 'mt-0 mb-14',
						confirmButton: 'px-5 mt-0 box-shadow-none',
						htmlContainer: 'mx-auto rounded-8 my-14',
						title: 'my-14 p-0',
						popup: 'py-52 px-57 rounded-8'
					}
		})
				.then(() => {
					setCircuit(response[0].data);
					if (params?.id) {
					  get(params.id);
					}
				});
		} else {
			sweetalert2.fire({
				title: 'Error',
				html: translator(response[0].data.message) || translator(response[1].data.message),
				icon: 'error',
				confirmButtonText: 'Ok',
				customClass: {
					icon: 'mt-0 mb-14',
					confirmButton: 'px-5 mt-0 box-shadow-none',
					htmlContainer: 'mx-auto rounded-8 my-14',
					title: 'my-14 p-0',
					popup: 'py-52 px-57 rounded-8'
				}
	});
		}
	};

	const changeLocation = async (address: IAddress) => {
		if (!circuit) return;

		setCircuit((prevState: ICircuit | undefined) => {
			if (!prevState) return prevState;

			return {
				...prevState,
				address,
			};
		});
	};

	if (!circuit)
		return (
			<SecureView permission={permission}>
				<div></div>
			</SecureView>
		);
	return (
		<SecureView permission={permission}>
			<ChangeAddressModal
				show={modalLocation}
				onSubmit={changeLocation}
				onClose={() => setModalLocation(false)}
			/>

			<Card className={"col-12 py-0 my-0"}>
				<CardBody className="p-0 m-0">
					<Row 
					style={{
						justifyContent: "center",
						alignItems: "center",
						paddingBottom: 40,
					}}
					>
						<ProfileImage uri={circuit.coverPicture || defaults.circuitCover} />
					</Row>
					<Row className="justify-content-center align-items-center mb-3">
						{/* <h3 className={'mt-2 mr-2'}>{circuit?.name}</h3> */}
							{/* <i className={"ti-pencil text-gray p-2 pointer"}></i> */}
						{editNameCircuit ? (
							<input 
							className={`mt-2 mr-2 font-poppins ${editNameCircuit ? 'border-bottom' : ''}`}
							style={{border: 'none', backgroundColor: 'transparent', width: 'fit-content', textAlign: 'center'}}
							type="text"
							placeholder="Circuito 001"
							value={circuit?.name}
							onChange={(e) => setValue("name", e.target.value)}
							/>
						) : (
							<h3 className={'mt-2 mr-2'}>{circuit?.name}</h3>
						)}
						<i className={`${editNameCircuit ? 'ti-check text-green' : 'ti-pencil text-gray'} p-2 pointer`} onClick={() => setEditNameCircuit(!editNameCircuit)}></i>
					</Row>
					<Row className="justify-content-center align-items-center">
						<p className={'text-gray text-md'}>ID: {circuit.id}</p>
					</Row>
					<Row className="justify-content-center align-items-center mb-3">
						<i className={"ti-user text-gray px-2"}></i>
						<p className={"text-gray text-md mb-0"}>Cliente desde: {circuit?.createdAt &&
						moment(circuit.createdAt).format("MMMM YYYY")}</p>
					</Row>
					<Row className="justify-content-center align-items-center mb-3">
						<FormGroup className="d-flex justify-content-center" row={true}>
							<FormControlLabel
								control={
									<Switch										
										onChange={(e, v) => setValue("premium", v)}
										checked={circuit.premium || false}
										sx={{
											'& .MuiSwitch-switchBase.Mui-checked': {
												color: '#a6051a',
												opacity: 1
											},
											'& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
												backgroundColor: '#a6051a',
												opacity: 1
											},
										}}
									/>
								}
								label={<Typography className="font-poppins font-medium">Premium</Typography>}
							/>
							<FormControlLabel
								control={
									<Switch
										//color={"primary"}
										onChange={(e, v) => setValue("open", v)}
										checked={circuit.open || false}
										sx={{
											'& .MuiSwitch-switchBase.Mui-checked': {
												color: '#a6051a',
											},
											'& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
												backgroundColor: '#a6051a',
											},
										}}
									/>
								}
								label={<Typography className="font-poppins font-medium">Abierto</Typography>}
							/>
						</FormGroup>
					</Row>



					<Box sx={{ width: '100%' }}>
						<Row className="mb-3">
												<Col>
													<ul className="nav nav-tabs justify-content-center">
														{tabs.map((tab) => (
															<li className="nav-item" key={tab.id}>
																<a
																		className={`nav-link border-0 font-poppins font-medium ${activeTab === tab.id ? "active-tab" : "text-black-onpole"}`}
																		style={{ color: "#a6051a" }}
																		href={`#`}
																		onClick={() => handleTabChange(tab.id)}
																>
																	{tab.label}
																</a>
																{activeTab === tab.id && <div className="line"></div>}
															</li>
														))}
													</ul>
												</Col>
											</Row>

						{activeTab === 'informacion-general' && (
							<>
							<Row style={{ justifyContent: "space-between", marginTop: '1rem', alignItems: 'center' }}>
								<FormControl variant="standard" sx={{ m: 1, minWidth: '40%' }}>
									<InputLabel className="font-poppins" id="demo-simple-select-standard-label">TIPO DE CIRCUITO</InputLabel>
									<Select
										labelId="demo-simple-select-standard-label"
										id="circuit-type"
										value={circuit.type}
										onChange={(e) => setValue("type", e.target.value)}
										label="Tipo de circuito"
									>
										<MenuItem value="-1">
											<em>None</em>
										</MenuItem>
										<MenuItem value={"indoor"}>Indoor</MenuItem>
										<MenuItem value={"outdoor"}>Outdoor</MenuItem>
										<MenuItem value={"indoor/outdoor"}>Indoor / Outdoor</MenuItem>
									</Select>
								</FormControl>

								<FormControl variant="standard" sx={{ m: 1, minWidth: '40%' }}>
									<TextField className="font-poppins" id="length" type={'number'} label="LONGITUD DEL CIRCUITO (metros)" variant="standard"	value={circuit?.length}
																				onChange={(e) => setValue("length", e.target.value)} />
								</FormControl>
							</Row>

							<Row style={{ justifyContent: "space-between", marginTop: '1rem', alignItems: 'center' }}>
								<FormControl variant="standard" sx={{ m: 1, minWidth: '40%' }}>
								<TextField className="font-poppins" id="website" label="SITIO WEB" variant="standard" 	value={circuit?.website}
																			onChange={(e) => setValue("website", e.target.value)} />
								</FormControl>
								<FormControl variant="standard" sx={{ m: 1, minWidth: '40%' }}>
								<TextField className="font-poppins" id="phone" 
								label="CONTACTO (añadir prefijo de país)" variant="standard" InputLabelProps={{ shrink: true }} value={
									(circuit?.phoneNumbers || []).length > 0
										? circuit?.phoneNumbers[0].number
										: circuit?.phoneNumbers
								}
								onChange={(e) => setValue("phone", e.target.value)} />
								</FormControl>
							</Row>
							<Row style={{ marginTop: '1rem' }}>
								<FormControl variant="standard" sx={{ m: 1, minWidth: '100%' }}>
									<InputLabel className="font-poppins" htmlFor="standard-adornment-password">DIRECCIÓN</InputLabel>
								<Input id="length" value={circuit?.address.address} disabled
																			onChange={(e) => setValue("address", e.target.value)}
																			endAdornment={
																				<InputAdornment position="end">
																					<IconButton
																						aria-label={'change-location'}
																						onClick={() => setModalLocation(!modalLocation)}
																					>
																						<i className={"ti-pencil"}></i>
																					</IconButton>
																				</InputAdornment>
																			}/>
							</FormControl>
						</Row>

							<Row style={{ justifyContent: "space-between", marginTop: '1rem', alignItems: 'center' }}>
								<FormControl variant="standard" sx={{ m: 1, minWidth: '30%' }}>
									<TextField id="zip" label="CÓDIGO POSTAL" type={'number'} variant="standard" 	value={circuit?.address.postalCode} disabled
																				onChange={(e) => setValue("website", e.target.value)} />
								</FormControl>
								<FormControl variant="standard" sx={{ m: 1, minWidth: '30%' }}>
									<TextField id="city" label="CIUDAD" variant="standard" 	value={circuit?.address.city} disabled
																				onChange={(e) => setValue("website", e.target.value)} />
								</FormControl>
								<FormControl variant="standard" sx={{ m: 1, minWidth: '30%' }}>
									<TextField id="region" label="REGIÓN" variant="standard" 	value={circuit?.address.province} disabled
																				onChange={(e) => setValue("website", e.target.value)} />
								</FormControl>
							</Row>

							<Row style={{ justifyContent: "space-between", marginTop: '1rem', alignItems: 'center' }}>
								<FormControl variant="standard" sx={{ m: 1, minWidth: '30%' }}>
									<TextField id="country" label="PAÍS" variant="standard" 	value={circuit?.address.country} disabled
																				onChange={(e) => setValue("website", e.target.value)} />
								</FormControl>
								<FormControl variant="standard" sx={{ m: 1, minWidth: '30%' }}>
									<TextField id="latitude" label="lATITUD" type={'number'} variant="standard" 	value={circuit?.address.latitude} disabled
																				onChange={(e) => setValue("website", e.target.value)} />
								</FormControl>
								<FormControl variant="standard" sx={{ m: 1, minWidth: '30%' }}>
									<TextField id="longitude" label="LONGITUD" type={'number'} variant="standard" 	value={circuit?.address.longitude} disabled
																				onChange={(e) => setValue("website", e.target.value)} />
								</FormControl>
							</Row>
						</>
						)}

						{activeTab === 'horarios' && (
							<CircuitHours forceReload={() => setForceReload(true)} circuit={circuit} />
						)}

						
						{activeTab === 'tipos-de-kart' && (
							<>
							<Row style={{ justifyContent: "space-between", marginTop: '1rem', alignItems: "baseline" }}>
								<FormControl variant="standard" sx={{ m: 1, minWidth: '45%' }}>
									<TextField className="font-poppins" id="longitude" label="Introduce el nombre del kart..." 
									variant="standard" 
									value={kart}
									onChange={(e) => setKart(e.target.value)}
									/>
								</FormControl>
								<FormControl variant="standard" sx={{ m: 1, minWidth: '45%' }}>
									<TextField
										className="font-poppins"
										id="longitude"
										label="Introduce el mejor tiempo..."
										type={'number'}
										variant="standard"
										value={time || ''}
										onChange={(e) => setTime(Number(e.target.value))}
										onBlur={(e) => {
											if (e.target.value === '') {
												setTime(0);
											}
										}}
									/>
								</FormControl>

								<Button className={'rounded-full btn-ligth'}
									style={{ boxShadow: "none", color: "transparent", marginRight: "9px" }}
									onClick={() => setValue("kart", kart)}
									id="button-addon2"
								>
									<i className={"ti-check icon-green text-lg"}></i>
								</Button>
							</Row>

							{circuit?.kartingTypes?.map((kart) => {
								const kbt = circuit?.kartingTimes?.find((c) => c.kart === kart);
								let time = 0;

								if (kbt) {
									time = kbt.time;
								}

								return (
									<Row style={{ marginTop: '1rem', alignItems: 'end' }}>
										<FormControl variant="standard" sx={{ m: 1, minWidth: '100%' }}>
										<img								    	
								    	style={{ position: 'relative', top: 22.5 }}
								    	width={15}
								    	height={"auto"}
								    	src={kartSelected}
								    	alt={"kart"}
								    />
											<Input id="length" className="px-4 font-poppins font-semibold" value={`${kart} - Mejor tiempo: ${time}`} disabled
																		endAdornment={
																			<InputAdornment className="font-poppins" position="end">
																				<IconButton
																					aria-label={'edit-kart'}
																					onClick={() => {
																						setKart(kart);
																						setTime(time);
																						setValue("kart", kart);
																					}}
																				>
																					<i className={"ti-pencil"}></i>
																				</IconButton>
																				<IconButton
																					aria-label={'delete-kart'}
																					onClick={() => setValue("kart", kart)}
																				>
																					<i className={"ti-trash text-red"}></i>
																				</IconButton>
																			</InputAdornment>
																		}/>
										</FormControl>
									</Row>
								);
							})}
							</>
						)}
						
						{activeTab === 'servicios' && (
							<Grid className="mt-4" container spacing={2} columnSpacing={12}>
									{constants.circuits.services.map((service, i) => {
										return (
											<Grid item xs={6}>
												<FormControl variant="standard" sx={{ m: 1, mr:3, minWidth: '100%' }}>
													<Input className="font-poppins font-semibold" id="length" value={translator(service.name)} disabled
																				startAdornment={
																					<InputAdornment position="start">
																					{serviceIcons[service.name] === steeringWheel ? (
																						<img className="mr-3" style={{ width: '16px', scale: '1.5'}} src={steeringWheel} alt="Simracing" />
																					) : (
																						<i className={`${serviceIcons[service.name]} mr-3`}/>
																					)}
																					</InputAdornment>
																				}
																				endAdornment={
																					<InputAdornment position="end">
																						<InputGroup.Checkbox className={'service-checkbox'}
																							onChange={() => setValue("service", service.id)}
																							checked={(circuit.services || []).includes(service.id)}
																							aria-label={service.name}
																						/>
																					</InputAdornment>
																				}/>
												</FormControl>
											</Grid>
										);
									})}
							</Grid>
						)}
					</Box>
				</CardBody>

				<CardBody className={'text-center'}>
					<Button
						variant="success"
						type="button"
						onClick={update}
						className={"mt-3 px-4 rounded-md"}
					>
						Guardar Cambios
					</Button>
				</CardBody>

				<CardBody>
				<div className="d-flex justify-content-between align-items-center mb-4">
					<h4 className="font-poppins">Usuarios <span className="font-semibold">{circuit?.users.length}</span></h4>
					<Button style={{ boxShadow: "none" }} className={`rounded-pill px-4 ${addUserShow ? 'btn btn-danger' : 'btn btn-info'}`} onClick={() => setAddUserShow(!addUserShow)}>{addUserShow ? 'Cancelar' : 'Agregar'}</Button>
				</div>
				{addUserShow && (				
				<div className="row my-4">
					<div className="col-6 px-0">
						<UserSearchDropdown onSelectUser={addUser} />
					</div>
				</div>
				)}
				{/* Lista de usuarios agregados al circuito */}
				{circuit?.users?.map((user: any, index) => (				
				 <div key={index} className="d-flex justify-content-between align-items-center mb-2 border-bottom">
				 	<span className="font-poppins font-14">{user}</span>
				 	<Button variant="danger" size="sm" onClick={() => removeUser(user)}>
				 	Eliminar
				 	</Button>
				 </div>
				))}
			</CardBody>
			</Card>
		</SecureView>
	);
}
