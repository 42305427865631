import IPermission from "../../core/interfaces/IPermission";
import EModules from "../../core/enums/EModules";
import ECommonPermissions from "../../core/enums/ECommonPermissions";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../core/store/store";
import {useNavigate} from "react-router-dom";
import {useEffect, useState} from "react";
import SecureView from "../../shared/common/SecureView";
import {Badge, Button} from "react-bootstrap";
import Paginator from "../../shared/common/Paginator";
import findTipRequest from "../../core/api/requests/tips/findTipRequest";
import moment from "moment";
import ITip from "../../core/interfaces/ITip";
import SeeTipModal from "../../shared/modals/SeeTipModal";
import CreateTipModal from "../../shared/modals/CreateTipModal";
import translator from "../../core/utils/translator";

const permission: IPermission = {
  module: EModules.TIPS,
  method: ECommonPermissions.FIND,
}

export default function TipsList () {
  const permissions = useSelector((state: RootState) => state.permissions)
  const dispatch = useDispatch();
  const navigator = useNavigate();

  const [ tips, setTips ] = useState<any[]>([]);

  const [ count, setCount ] = useState<number>(0);

  const [ totalPages, setTotalPages ] = useState<number>(1);
  const [ page, setPage ] = useState<number>(1);
  const [ perPage, setPerPage ] = useState<number>(30);
  const [ query, setQuery ] = useState<string>('');

  const [ selectedTip, setSelectedTip ] = useState<ITip>()

  const [ createModal, setCreateModal ] = useState<boolean>(false)

  const changePageAndFind = (page: number) => {
    find(page);
  }

  const find = async (pageByParam?: number) => {
    if (pageByParam) setPage(pageByParam)

    const response = await findTipRequest(
      dispatch,
      {
        page: pageByParam || page,
        perPage,
        query,
      },
    )

    if (response?.data) {
      setTips(response.data?.data || [])
      setCount(response.data?.count || 0)

      setPage(response.data?.pagination?.page || 1)
      setPerPage(response.data?.pagination?.perPage || 30)
      setTotalPages(response.data?.pagination?.total || 1)
    }
  }

  useEffect(() => {
    find()
  }, [])

  useEffect(() => {
    setPage(1)
  }, [query])

  const closeModal = () => {
    setSelectedTip(undefined)
    setCreateModal(false)
    find()
  }

  return <SecureView permission={permission}>
    <SeeTipModal
      onClose={closeModal}
      show={!!selectedTip}
      tip={selectedTip}
    />

    <CreateTipModal
      show={createModal}
      onClose={closeModal}
    />
    <header className="row col-12 my-4 rounded-10 p-4 border border-2 border-black align-items-center">
				<div className={"col-9 gap-1 d-flex flex-column"}>
					<h2 className="text-24 font-medium">Consejos Totales</h2>
					<span className="text-24 font-bold mt-2">{count}</span>
				</div>
				<div className={"col-3"}>
        <Button
              onClick={() => setCreateModal(true)}
              className="btn btn-info float-right px-4"
            >Nuevo Consejo
            </Button>
							</div>
			</header>
      <div className="table-responsive row col-12 p-0 rounded-t-10">
            <table className="table table-bordered table-hover font-16 table-rounded">
              <thead>
              <tr>
                <th className="font-semibold">Fecha</th>
                <th className="font-semibold">Título</th>
                <th className="font-semibold">Creador</th>
                <th className="font-semibold">Nivel</th>
                <th className="font-semibold">Acciones</th>
              </tr>
              </thead>
              <tbody>
              {tips.map((tip: ITip) => {
                return <tr key={tip.id}>
                  <td>{moment(tip.createdAt).format('DD/MM/YYYY')}</td>
                  <td>{tip.title}</td>
                  <td>{tip.owner}</td>
                  <td>
                    <Badge bg={'primary'} className={'text-white'}>{translator(tip.level)}</Badge>
                  </td>
                  <td>
                    <Button size={'sm'} variant={'secondary'} onClick={() => setSelectedTip(tip)}>
                      <i className={'ti-eye'}></i>
                    </Button>
                  </td>
                </tr>
              })
              }
              </tbody>
            </table>

            <Paginator
              total={totalPages}
              page={page}
              goTo={changePageAndFind}
            />
          </div>
  </SecureView>
}
