export default {
  circuits: {
    services: [
      {
        id: 'freeParking',
        name: 'free parking',
      },
      {
        id: 'coffeeShop',
        name: 'coffee shop',
      },
      {
        id: 'restaurant',
        name: 'restaurant',
      },
      {
        id: 'bar',
        name: 'bar',
      },
      {
        id: 'terrace',
        name: 'terrace',
      },
      {
        id: 'market',
        name: 'market',
      },
      {
        id: 'wifi',
        name: 'wifi',
      },
      {
        id: 'disabledAccess',
        name: 'disabled access',
      },
      {
        id: 'dressingRooms',
        name: 'dressing rooms',
      },
      {
        id: 'transport',
        name: 'transport',
      },
      {
        id: 'zonaOcio',
        name: 'zona ocio',
      },
      {
        id: 'paintball',
        name: 'paintball',
      },
      {
        id: 'simracing',
        name: 'Simracing',
      }
    ]
  },
  karts: [
    { id: 'Sodi Kart', name: 'Sodi Kart' },
    { id: 'Kart Adulto 270cc', name: 'Kart Adulto 270cc' },
    { id: 'Kart GTR 270cc', name: 'Kart GTR 270cc' },
    { id: 'Sodi Kart RX7 270cc', name: 'Sodi Kart RX7 270cc' },
    { id: 'Sodi GT4R 390cc', name: 'Sodi GT4R 390cc' },
    { id: 'Especial 270cc', name: 'Especial 270cc' },
    { id: '420 Finestrat 420cc', name: '420 Finestrat 420cc' },
    { id: 'Senior 270cc', name: 'Senior 270cc' },
    { id: 'Cadete 200cc', name: 'Cadete 200cc' },
    { id: 'Kart 270cc', name: 'Kart 270cc' },
    { id: 'Kart 390cc', name: 'Kart 390cc' },
    { id: 'Sodi GT5 270cc', name: 'Sodi GT5 270cc' },
    { id: 'Kar RT8 390cc', name: 'Kar RT8 390cc' },
    { id: 'Kart Senior 270cc', name: 'Kart Senior 270cc' },
    { id: 'Kart ProSenior 390cc', name: 'Kart ProSenior 390cc' },
    { id: 'Kart Adulto', name: 'Kart Adulto' },
    { id: 'GT420 Extreme 420cc (15CV)', name: 'GT420 Extreme 420cc (15CV)' },
    { id: 'Kart F-300', name: 'Kart F-300' },
    { id: 'Kart RX8 390cc', name: 'Kart RX8 390cc' },
    { id: 'Rotax Max 2T Competición', name: 'Rotax Max 2T Competición' },
    { id: 'Adulto GX 270cc', name: 'Adulto GX 270cc' },
    { id: 'Senior RT8 270cc', name: 'Senior RT8 270cc' },
    { id: 'Kart Adulto 270c', name: 'Kart Adulto 270c' },
    { id: 'Kart Adulto 390cc', name: 'Kart Adulto 390cc' },
    { id: 'Kart 420cc', name: 'Kart 420cc' },
    { id: 'Kart Senior 390cc', name: 'Kart Senior 390cc' },
    { id: 'Adulto Sodi RT8 400cc', name: 'Adulto Sodi RT8 400cc' },
    { id: 'Adulto Sodi RT8 270cc', name: 'Adulto Sodi RT8 270cc' },
    { id: 'SodiKart SR4 270cc', name: 'SodiKart SR4 270cc' },
    { id: 'SodiKart GT4 270cc', name: 'SodiKart GT4 270cc' },
    { id: 'Rotax (2T-22CV)', name: 'Rotax (2T-22CV)' },
    { id: 'Adulto Sodi RT 270cc', name: 'Adulto Sodi RT 270cc' },
    { id: 'Adulto Sport 270cc', name: 'Adulto Sport 270cc' },
    { id: 'SodiKart SR4 390cc', name: 'SodiKart SR4 390cc' },
    { id: 'Adulto SodiKart 270cc', name: 'Adulto SodiKart 270cc' },
    { id: 'Kart Adulto 200cc', name: 'Kart Adulto 200cc' },
    { id: 'Kart GT 270cc', name: 'Kart GT 270cc' },
    { id: 'Kart Racing 390cc', name: 'Kart Racing 390cc' },
    { id: 'Kart adulto 270cc', name: 'Kart adulto 270cc' },
    { id: 'SuperKart 300cc', name: 'SuperKart 300cc' },
    { id: 'Kart Competición 400cc', name: 'Kart Competición 400cc' },
    { id: 'Kart Junior 270cc', name: 'Kart Junior 270cc' },
    { id: 'SuperKart 390cc', name: 'SuperKart 390cc' },
    { id: 'SR3 270cc', name: 'SR3 270cc' },
    { id: 'Honsa 390cc', name: 'Honsa 390cc' },
    { id: 'Rotax 25cc 2T (Competición)', name: 'Rotax 25cc 2T (Competición)' },
    { id: 'Sodi RX7 270cc', name: 'Sodi RX7 270cc' },
    { id: 'Sodi Kart RX8 270cc', name: 'Sodi Kart RX8 270cc' },
    { id: 'Sodi RX8 390cc', name: 'Sodi RX8 390cc' },
    { id: 'Honda 270cc', name: 'Honda 270cc' },
    { id: 'Honda 120cc', name: 'Honda 120cc' },
    { id: 'Super Kart 270cc', name: 'Super Kart 270cc' },
    { id: 'Kart Racing 270cc', name: 'Kart Racing 270cc' },
    { id: 'Superkart 240cc', name: 'Superkart 240cc' },
    { id: 'Superkart 390cc', name: 'Superkart 390cc' },
    { id: 'Kart Standard 270cc', name: 'Kart Standard 270cc' },
    { id: 'Sodi Kart SR4 270cc', name: 'Sodi Kart SR4 270cc' },
    { id: 'Superkart 270cc', name: 'Superkart 270cc' },
    { id: 'Kart F1 400cc', name: 'Kart F1 400cc' },
    { id: 'F2 270cc', name: 'F2 270cc' },
    { id: 'Kart 2T 125cc', name: 'Kart 2T 125cc' },
    { id: 'Sodi Kart RT8 270cc', name: 'Sodi Kart RT8 270cc' },
    { id: 'Kart Competición', name: 'Kart Competición' },
    { id: 'Kart Súper 270cc', name: 'Kart Súper 270cc' },
    { id: 'Kart F1 200cc', name: 'Kart F1 200cc' },
    { id: 'Biz Pro 400cc', name: 'Biz Pro 400cc' },
    { id: 'Kart Adulto 270cc (limitado)', name: 'Kart Adulto 270cc (limitado)' },
    { id: 'Kart Competición RT8 270cc (sin limitar)', name: 'Kart Competición RT8 270cc (sin limitar)' },
    { id: 'Senior Kart 200cc', name: 'Senior Kart 200cc' },
    { id: 'Super Kart 200cc', name: 'Super Kart 200cc' },
    { id: 'Pro Kart 270cc', name: 'Pro Kart 270cc' },
    { id: 'Speedy 200cc', name: 'Speedy 200cc' },
    { id: 'Super Kart 390cc', name: 'Super Kart 390cc' },
    { id: 'E-Storm', name: 'E-Storm' },
    { id: 'E-Flash', name: 'E-Flash' },
    { id: 'E-Turbo', name: 'E-Turbo' },
    { id: 'Super 270cc', name: 'Super 270cc' },
    { id: 'Racing 390cc', name: 'Racing 390cc' },
    { id: 'Speedy Sport', name: 'Speedy Sport' },
    { id: 'Sodi RT10 270cc', name: 'Sodi RT10 270cc' },
    { id: 'Sodi Funkid 120cc', name: 'Sodi Funkid 120cc' },
    { id: 'Senior Kart 270cc', name: 'Senior Kart 270cc' },
    { id: 'Competition Kart 390cc', name: 'Competition Kart 390cc' },
    { id: 'Pro Kart Extreme 400cc', name: 'Pro Kart Extreme 400cc' },
    { id: 'Kart Senior', name: 'Kart Senior' },
    { id: 'Superkart', name: 'Superkart' },
    { id: 'Kart Competición 125cc-2T', name: 'Kart Competición 125cc-2T' },
    { id: 'Sodi GT4 207cc', name: 'Sodi GT4 207cc' },
    { id: 'Sodi RT8 390cc', name: 'Sodi RT8 390cc' },
    { id: 'Sodikart RX70 270cc', name: 'Sodikart RX70 270cc' },
    { id: 'Adulto SR4 310cc', name: 'Adulto SR4 310cc' },
    { id: 'Sodikart RX7 270cc', name: 'Sodikart RX7 270cc' },
    { id: 'Kart de Competición', name: 'Kart de Competición' },
    { id: 'Kart EK3 Honda 270cc', name: 'Kart EK3 Honda 270cc' },
    { id: 'Racing 400cc', name: 'Racing 400cc' },
    { id: 'Competición  2T 100cc', name: 'Competición  2T 100cc' },
    { id: 'RT8 270cc', name: 'RT8 270cc' },
    { id: 'Sodi Kart 270cc', name: 'Sodi Kart 270cc' },
    { id: 'KZ2', name: 'KZ2' },
    { id: 'Kart RT8 270cc', name: 'Kart RT8 270cc' },
    { id: 'Kart RT10 390cc', name: 'Kart RT10 390cc' },
    { id: 'Adulto Kart 390cc', name: 'Adulto Kart 390cc' },
    { id: 'Senior Plus 420cc', name: 'Senior Plus 420cc' },
    { id: 'Sodi RX7 390cc', name: 'Sodi RX7 390cc' },
    { id: 'Kart F300cc', name: 'Kart F300cc' },
    { id: 'Sodi SR4 Adulto', name: 'Sodi SR4 Adulto' },
    { id: 'Sodi LR5 Junior', name: 'Sodi LR5 Junior' },
    { id: 'SuperKart 400cc', name: 'SuperKart 400cc' },
    { id: 'Racing Kart 420cc', name: 'Racing Kart 420cc' },
    { id: 'Kart Adulto V2', name: 'Kart Adulto V2' },
    { id: 'Kart Adulto V3', name: 'Kart Adulto V3' },
    { id: 'Kart Formula Adulto 270cc', name: 'Kart Formula Adulto 270cc' },
    { id: 'Sodikart SR4 270cc', name: 'Sodikart SR4 270cc' },
    { id: 'CRG Centurion 400cc', name: 'CRG Centurion 400cc' },
    { id: 'Super Kart Special 350cc', name: 'Super Kart Special 350cc' },
    { id: 'Kart 2T Competición', name: 'Kart 2T Competición' },
    { id: 'Kart Basic 270cc', name: 'Kart Basic 270cc' },
    { id: 'Kart Pro 390cc', name: 'Kart Pro 390cc' },
    { id: 'Kart Max 125cc 2T', name: 'Kart Max 125cc 2T' },
    { id: 'Kart Promo 200cc', name: 'Kart Promo 200cc' },
    { id: 'Kart Super Promo 270cc', name: 'Kart Super Promo 270cc' },
    { id: 'Kart Speedy Standard 270cc', name: 'Kart Speedy Standard 270cc' },
    { id: 'Kart Super Sodi 390cc', name: 'Kart Super Sodi 390cc' },
    { id: 'Racer 200cc', name: 'Racer 200cc' },
    { id: 'Adulto Honda 270cc', name: 'Adulto Honda 270cc' },
    { id: 'F400 390cc', name: 'F400 390cc' },
    { id: 'Pro-Racer 270cc', name: 'Pro-Racer 270cc' },
    { id: 'Kart Juvenil 200cc', name: 'Kart Juvenil 200cc' },
    { id: 'sodiKart390cc', name: 'Sodi Kart 390cc' },
  ],
}
