import IPermission from "../../core/interfaces/IPermission";
import EModules from "../../core/enums/EModules";
import ECommonPermissions from "../../core/enums/ECommonPermissions";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../core/store/store";
import { useNavigate } from "react-router-dom";
import SecureView from "../../shared/common/SecureView";
import { Row } from "react-bootstrap";
import * as React from "react";
import { useState } from "react";

const permission: IPermission = {
	module: EModules.TIPS,
	method: ECommonPermissions.FIND,
}

export default function () {
	const permissions = useSelector((state: RootState) => state.permissions)
	const dispatch = useDispatch();
	const navigator = useNavigate();

	const [ yearly, setYearly ] = useState<boolean>(true);
	const [ basicPlan, setBasicPlan ] = useState<boolean>(true);

	const createData = (name: string,
																					basic: boolean,
																					advanced: boolean,
	) => {
		return { name, basic, advanced };
	};

	const rows = [
		createData("Posicionar Circuito en la App", true, true),
		createData("Gestión de Información", true, true),
		createData("Análisis de Métricas", true, true),
		createData("Análisis de Métricas Avanzadas", false, true),
		createData("Publicación de Eventos", false, true),
		createData("Gestión de Inscripciones a Eventos*", false, true),
		createData("Servicio de Soporte Rápido", false, true),
	];

	return <SecureView permission={permission}>
		<section className="mx-5">
			<h3 className="font-poppins font-bold mb-0 pt-5 pb-4 text-uppercase">Planes</h3>
			<hr className="my-0" />
			<article className="my-4 py-3 px-4 border-plans rounded-10">
				<h4 className="font-poppins font-semibold">
					<i className={'ti-rocket mr-2 text-red'} />
					Mejora a OnPole Manager Avanzado
				</h4>
				<p className={'text-gray font-14 font-poppins font-regular mb-0'}>
					Mejora tu plan a OnPole Manager Avanzado para desbloquear inmediatamente el acceso a todos los servicios exclusivos de la plataforma como eventos, tandas, ofertas y carreras.
				</p>
			</article>
			<hr className="my-0" />
			<Row className="m-0 px-0 my-5 flex-plans flex-plans-1920" style={{ alignItems: "start"}}>
					<div>
						<h3 className="font-poppins mb-4">Facturación</h3>
						<Row className='bg-light rounded-pill p-1 center'>
							<div className={yearly ? 'text-gray bg-gray-ligth mr-2 pointer font-poppins px-4 py-2' : 'bg-white font-poppins rounded-pill mr-2 pinter px-4 py-2'} onClick={() => setYearly(!yearly)}>Mensual</div>
							<div className={!yearly ? 'text-gray bg-gray-ligth pointer px-4 py-2 font-poppins' : 'font-poppins bg-white rounded-pill pinter px-4 py-2'} onClick={() => setYearly(!yearly)}>Anual</div>
						</Row>
					</div>

					<div className="-translate-x-1920">
						<Row className={'center mb-4'}>
							<h4 className={'text-gray mr-2 my-0'}>Manager Básico</h4>
							<span style={{ backgroundColor: "#828788"}} className={'px-1 text-white font-14 rounded'}>{yearly ? '-16%' : '-15%'}</span>
						</Row>
						<p style={{ fontSize: "36px" }} className={'mb-3 font-semibold'}>{yearly ? '24.99€' : '29.99€'}<span className={'text-xs'}> / mes</span></p>
						<p className={'font-14 text-gray'}><s>{yearly ? '29.99€' : '34.99€'} / mes</s></p>
						<div className={'mt-5 text-center'}>
							<p className={basicPlan ? 'text-red' : 'bg-primary text-white rounded-pill px-4 py-2 pointer'}
										onClick={() => {
											if(!basicPlan)
											setBasicPlan(!basicPlan);
										}}>
								{basicPlan ? 'Plan Actual' : 'Suscríbete'}
							</p>
						</div>
					</div>

					<div className="translate-x-1920">
						<Row className={'center mb-4'}>
							<h4 className={'text-gray mr-2 my-0'}>Manager Avanzado</h4>
							<span style={{ backgroundColor: "#828788"}} className={'px-1 text-white font-14 rounded'}>{yearly ? '-16%' : '-20%'}</span>
						</Row>
						<p style={{ fontSize: "36px" }} className={'mb-3 font-semibold'}>{yearly ? '49.99€' : '59.99€'}<span className={'text-xs'}> / mes</span></p>
						<p className={'font-14 text-gray'}><s>{yearly ? '59.99€' : '74.99€'}  / mes</s></p>
						<div className={'mt-5 text-center'}>
							<p className={!basicPlan ? 'text-red' : 'bg-primary text-white rounded-pill px-4 py-2 pointer'}
										onClick={() => {
											if(basicPlan)
												setBasicPlan(!basicPlan);
										}}>
								{!basicPlan ? 'Plan Actual' : 'Suscríbete'}
							</p>
						</div>
					</div>
				</Row>

				<div className={'mb-4'}>
				{rows.map((elm) =>{
					return <Row className={'b-b py-2 m-0 p-0 flex-plans'}style={{ alignItems: "start"}}>
						<div className={'text-md text-gray  w-item-plan'}>{elm.name}</div>
						<div className="item-basic">{elm.basic ? <i className={'ti-check text-lg text-red'}/>: ''}</div>
						<div className="item-advanced">{elm.advanced ? <i style={{marginRight: '5rem'}} className={'ti-check text-lg text-red'}/>: ''}</div>
					</Row>
				})}
				</div>

				<p className={'font-14 text-gray'}>*Comisiones y condiciones especiales en pagos dentro de la aplicación.</p>
		</section>		
	</SecureView>
}
